
@font-face {
  font-family: "Light";   /*Can be any text*/
  src: local("MyPixodoxFtLight"),
    url("./assets/fonts/MyPixodoxFtLight.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";   /*Can be any text*/
  src: local("MyPixodoxFtMedium"),
    url("./assets/fonts/MyPixodoxFtMedium.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";   /*Can be any text*/
  src: local("MyPixodoxFtBold"),
    url("./assets/fonts/MyPixodoxFtBold.ttf") format("truetype");
}

@font-face {
  font-family: "Regular";   /*Can be any text*/
  src: local("MyPixodoxFtRegular"),
    url("./assets/fonts/MyPixodoxFtRegular.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: 'Regular', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
